import React from "react";
import styled from "@emotion/styled";
import { graphql, useStaticQuery } from "gatsby";

import Section from "@components/Section";
import SEO from "@components/SEO";
import Layout from "@components/Layout";
import Paginator from "@components/Navigation/Navigation.Paginator";
import NavCategory from '@components/Navigation/Navigation.Categories';

import ArticlesList from "../sections/articles/Articles.List";

import mediaqueries from '@styles/media';
import { Template } from "@types";

const siteQuery = graphql`
  {
    allSite {
      edges {
        node {
          siteMetadata {
            name
            title
          }
        }
      }
    }
  }
`;

const ArticlesPage: Template = ({ location, pageContext }) => {
  // const articles = pageContext.group;
  const { group: articles, category } = pageContext;
  const authors = pageContext.additionalContext.authors;

  const results = useStaticQuery(siteQuery);
  const site = results.allSite.edges[0].node.siteMetadata;

  return (
    <Layout>
      <SEO 
        pathname={location.pathname}
        title={site.title + " - " + site.name}
      />
      <Section narrow>
        <LayoutContainer>
          <CategoryBarWrap>
            <CategoryBar>
              <NavCategory />
            </CategoryBar>
          </CategoryBarWrap>
          <div>
            <ArticlesList articles={articles} />
            <ArticlesPaginator show={pageContext.pageCount > 1}>
              <Paginator {...pageContext} />
            </ArticlesPaginator>
          </div>
        </LayoutContainer>
      </Section>
    </Layout>
  );
};

export default ArticlesPage;

const ArticlesPaginator = styled.div<{ show: boolean }>`
  ${p => p.show && `margin-top: 64px;`}
`;

const LayoutContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 288px 1fr;
  grid-template-rows: 2;
  column-gap: 32px;
  row-gap: 32px;

  ${mediaqueries.desktop_medium`
    grid-template-columns: 1fr;
  `}
`;

const CategoryBarWrap = styled.div`
  position: relative;
  z-index: 100;
`;

const CategoryBar = styled.div`
  position: sticky;
  top: 16px;
`;
